<template>
  <div class="benefits">

    <div class="benefit-card">
      <div class="benefit-card__img">
        <img v-lazy="`$/images/auth/benefits_icon_1.svg`" alt="benefits icon">
      </div>
      <p class="benefit-card__desc"><span class="benefit-card__desc--brand">Collect Pouch Points</span> on participating retailers.</p>
    </div>

    <div class="benefit-card">
      <div class="benefit-card__img">
        <img v-lazy="`$/images/auth/benefits_icon_2.svg`" alt="benefits icon">
      </div>
      <p class="benefit-card__desc"><span class="benefit-card__desc--brand">Redeem your points</span> for Gift Cards!</p>
    </div>

    <div class="benefit-card">
      <div class="benefit-card__img">
        <img v-lazy="`$/images/auth/benefits_icon_3.svg`" alt="benefits icon">
      </div>
      <p class="benefit-card__desc">More ways to save <br /> <span class="benefit-card__desc--brand">coming soon.</span></p>
    </div>

  </div>
</template>

<script>
export default {
  props: {},
  methods: {}
}
</script>

<style lang="sass" scoped>
.benefits
  display: flex
  flex-wrap: wrap

.benefit-card
  text-align: center
  width: 250px
  margin: 10px auto 32px auto
  &__img
    margin: 0 auto 24px auto
  &__desc
    font-size: 20px
    line-height: 150%
    letter-spacing: 0.16px
    color: #7A7A7A
    &--brand
      color: #FF6441
      font-weight: 600

</style>
