<template>

  <div>

    <auth-input
      :hideable="false"
      :label="label"
      :placeholder="label"
      :type="'text'"
      :initial="initial || ''"
      v-on:input="onInput($event)"
      :disabled="disabled"
    ></auth-input>

  </div>

</template>

<script>
import AuthInput from './AuthInput'

export default {
  components: { AuthInput },
  props: {
    initial: String,
    disabled: Boolean,
    label: String
  },
  methods: {
    /**
     * Test input validity
     */
    isValid (value) {
      return value && /^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]{1,30}$/.test(value) && value.indexOf(' ') < 0
    },

    /**
     * Handle the input event
     */
    onInput (e) {
      this.$emit('input', e)
      // return ony if valid
      this.$emit('valid', this.isValid(e))
    }
  }
}
</script>
