<template>
  <!-- On Submit Validation Messages -->
  <p
    v-if="error"
    class="
      is-size-7
      has-text-left
      has-text-weight-medium
    "
    :class="
      error.includes('already exists') ? 'special' : 'message'
    "
  >
    <!-- The validation message -->
    <span
      v-if="error.includes('please sign in using')"
    >
      <img
        class="cross"
        v-lazy="'$/images/auth_error.svg'"
        alt="Red cross"
      >

      <!-- Google -->
      <span
        v-if="error.includes('google')"
      >
        An account already exists with this email address,
        <router-link
          class="has-text-primary is-underline"
          :to="{ name: 'login' }"
        >
          please sign in using Google.
        </router-link>
      </span>

      <!-- Facebook -->
      <span
        v-if="error.includes('facebook')"
      >
        An account already exists with this email address,
        <router-link
          class="has-text-primary is-underline"
          :to="{ name: 'login' }"
        >
          please sign in using Facebook.
        </router-link>
      </span>
    </span>

    <span
      v-else-if="error === 'An account with the given email already exists.'"
    >
      <img
        class="cross"
        v-lazy="'$/images/auth_error.svg'"
        alt="Red cross"
      >

      <span>
        An account with the given email already exists.

      </span>

      <router-link
        class="has-text-primary is-underline"
        :to="{ name: 'login' }"
      >
        please sign in.
      </router-link>
    </span>

    <span
      v-else
    >
      <span>
        <img
          class="cross"
          v-lazy="'$/images/auth_error.svg'"
          alt="Red cross"
        >
      </span>

      <span>
        {{ error }}
      </span>

    </span>
  </p>
</template>

<script>
export default {
  props: {
    error: String,
    email: String
  }
}
</script>

<style lang="sass" scoped>
p:not(.message)
  height: 1.4rem !important

  & a
    text-decoration: underline

p.message
  color: #FF3223
  background: transparent
  height: 0
  line-height: 1.6rem
  margin-bottom: 1.4rem
  display: contents

  .cross
    content: ''
    width: 16px
    height: 8px
    margin-left: 0.2rem
    display: inline-block

p.special
  color: #FF3223
  background: transparent
  height: 0
  line-height: 1.6rem
  margin-bottom: 2rem
  display: contents
</style>
