
<template>
  <div>
    <div class="join">
      <join-intro class="intro"></join-intro>

      <div class="form-block">
        <div class="form-block__wrapper">

          <!-- Main Heading for Flow -->
          <h2 class="form-block__title is-size-3 has-text-weight-black">
            Join <span class="has-text-primary">Pouch</span>
          </h2>

          <div>
            <!-- The Credentials Form for Join Flow -->
            <form @submit.prevent="handleSubmit">

              <!-- First Name Field -->
              <name-field
                :label="'First Name'"
                v-on:valid="validFirstName = $event"
                v-on:input="firstNameValue = $event"
              ></name-field>

              <!-- Last Name Field -->
              <name-field
                :label="'Last Name'"
                v-on:valid="validLastName = $event"
                v-on:input="lastNameValue = $event"
              ></name-field>

              <!-- Email Field -->
              <email-field
                v-on:valid="validEmail = $event"
                v-on:input="emailValue = $event"
              ></email-field>

              <!-- Password Field: Validation UI, No Confirmation -->
              <password-field
                :is-confirmed="false"
                :is-validated="true"
                v-on:valid="validPassword = $event"
                v-on:input="passwordValue = $event"
              ></password-field>

              <!-- On Submit Validation Messages -->
              <join-errors
                :error="submissionError"
                :email="emailValue"
              >
              </join-errors>

              <div class="consent-actions">
                <label
                  class="checkbox is-size-7 has-text-grey-dark"
                >
                  <div class="check" :class="termsCheckbox && 'active'" @click="toggle()"></div>
                  <span>
                    I agree to the <router-link
                      :to="{ name: 'privacypolicy' }" target="_blank">Privacy Policy</router-link> and
                      <router-link target="_blank" :to="{ name: 'tandcs' }">Terms &amp; Conditions</router-link>
                  </span>
                </label>
              </div>

              <!-- Form Submit Button -->
              <button
                class="
                  button
                  is-medium
                  has-background-grey-lighter
                  is-fullwidth
                  has-text-weight-semibold
                  has-text-white
                  valid
                "
              >
                <span class="is-size-6">
                  Join with Email
                </span>
              </button>

            </form>
          </div>

          <!-- Conditions of Use -->
          <!-- we can add back the commented code once we have google captcha -->
          <!-- <conditions-of-use class="conditions-of-use" :isJoin="true"></conditions-of-use> -->

          <!-- Switch to Login Flow -->
          <p class="is-size-6 has-text-weight-medium">
            Already a member?
            <router-link
              class="has-text-primary has-text-weight-black"
              :to="{ name: 'login' }"
            >Log in</router-link>
          </p>
        </div>
      </div>

      <benefits-cards class="benefits"></benefits-cards>

    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import EmailField from './components/EmailField'
import PasswordField from './components/PasswordField'
import JoinIntro from './components/JoinIntro.vue'
import BenefitsCards from './components/BenefitsCards.vue'
import JoinErrors from './JoinErrors.vue'
import NameField from './components/NameField.vue'

export default {
  components: { EmailField, PasswordField, JoinIntro, BenefitsCards, JoinErrors, NameField },

  data: () => ({
    // User First name validation
    firstNameValue: '',
    validFirstName: false,

    // User Last name validation
    lastNameValue: '',
    validLastName: false,

    // User email validation
    emailValue: '',
    validEmail: false,

    // Password validation
    passwordValue: '',
    validPassword: false,

    // Form was submitted and failed
    submissionError: '',
    msg: null,

    // terms and condition checkbox
    termsCheckbox: false

  }),

  methods: {
    ...mapActions('account/', ['register']),
    /**
     * For the case the user try to join with native while he has already a social login account
     * the server would return an error to login using social
     * this function make the part of the error `please sign in using <>` as a link to login page
     */
    createLinkInErrorMsg () {
      if (this.submissionError.includes('An account already exists with this email address, please sign in using')) {
        this.msg = this.submissionError.split(',')
        return true
      }
      return false
    },

    /**
     * Register the user on valid state and redirect to success view.
     */
    async handleSubmit () {
      if (!this.firstNameValue) {
        this.submissionError = 'Please enter a valid first name'
        return
      }

      if (this.firstNameValue.indexOf(' ') >= 0) {
        this.submissionError = 'White space are not allow in First name field'
        return
      }

      // if first name is not valid
      if (!this.validFirstName) {
        this.submissionError = 'First name is not valid'
        return
      }

      if (!this.lastNameValue) {
        this.submissionError = 'Please enter a valid last name'
        return
      }

      if (this.lastNameValue.indexOf(' ') >= 0) {
        this.submissionError = 'White space are not allow in Last name field'
        return
      }

      // if first name is not valid
      if (!this.validLastName) {
        this.submissionError = 'Last name is not valid'
        return
      }

      if (!this.emailValue) {
        this.submissionError = 'Please enter a valid email'
        return
      }

      // if email is in the form a@a.com
      if (!this.validEmail) {
        this.submissionError = 'Email is not valid'
        return
      }

      // if password has a value
      if (!this.passwordValue) {
        this.submissionError = 'Please enter a password'
        return
      }

      // if password not valid
      if (!this.validPassword) {
        this.submissionError = 'Invalid password'
        return
      }

      if (!this.termsCheckbox) {
        this.submissionError = 'Please confirm the user terms and conditions'
        return
      }

      await this.register({
        credentials: {
          email: this.emailValue,
          password: this.passwordValue,
          firstName: this.firstNameValue,
          lastName: this.lastNameValue,
          // profile hold the value for the term and condition value
          profile: this.termsCheckbox ? 'true' : ''
        }
      })
        .then(() => {
          this.$router.push({
            name: 'join-complete',
            query: {
              jce: btoa(this.emailValue)
            }
          })
        })
        .catch(e => this.submissionError = e.message)

      this.$emit('loading', false)
    },

    /**
     * Validates if emailValue is an email.
     */
    validateEmail () {
      return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(this.emailValue)
    },
    toggle () {
      this.termsCheckbox = !this.termsCheckbox
    }
  },

  mounted () {
    this.$messages.removeShown('auth')
    this.$emit('has-notification', false)
  }
}
</script>

<style lang="sass" scoped>
p:not(.message)
  height: 1.4rem !important
  text-align: center

  & a
    text-decoration: underline

p.message
  color: #FF3223
  background: transparent
  height: 0rem
  line-height: 1.6rem
  margin-bottom: 0.2rem

  & > span::before
    content: ''
    width: 16px
    height: 8px
    margin-left: 0.2rem
    display: inline-block
    background: url(/static/images/auth_error.svg) no-repeat left bottom
h3
  margin-top: 2rem

div > a
  text-decoration: underline
  font-size: 0.8rem

  &:hover
    color: #FF6441 !important

form
  div.control
    & > input
      height: 3.2rem
      margin: 0.4rem 0
      padding-left: calc(1.4rem - 1px)
      border-color: #D8D8D8

      &.active
        border-color: #FF6441

      &::placeholder
        color: #363636
        opacity: 0.5

    & > label:not(.checkbox)
      background: linear-gradient(to top, #FFFFFF 0%, #FFFFFF 60%, rgba(255, 255, 255, 0) 61%, rgba(255, 255, 255, 0) 100%)
      margin: 0 0.8rem
      padding: 0 0.2rem
      position: absolute
      line-height: 1.25
      z-index: 1

      &.active
        color: #FF6441 !important

    & > img
      position: absolute
      top: 0.6rem
      right: 0.2rem
      padding: 1rem

      &:hover
        cursor: pointer

  button
    margin: 1rem 0
    border-radius: 4px

    &.valid
      background: #FF6441 !important

      &:hover
        background: #FF785A !important

.join
  margin: 50px 0 100px 0
  @media screen and ( min-width: 1024px )
    display: grid
    grid-template-areas: "intro form" "benefits form"

.intro
  grid-area: intro
  margin-top: 60px
  @media screen and ( min-width: 1024px )
    display: flex;
    flex-direction: column;
    justify-content: center;

.benefits
  grid-area: benefits

  @media screen and ( min-width: 1024px )
    margin-right: 50px
  @media screen and ( max-width: 768px )
    margin-top: 20px

.form-block
  grid-area: form
  &__wrapper
    @media screen and ( min-width: 768px )
      max-width: 464px
      margin: 0 auto
      padding: 32px 72px
    @media screen and ( min-width: 1024px )
      background-color: #FFF
  &__title
    display: none
    width: 100%
    font-weight: 600
    font-size: 27px
    line-height: 33px
    text-align: center
    letter-spacing: 0.27px
    color: #363636
    margin: 0 0 16px 0
    @media screen and ( min-width: 1024px )
      display: inline-block

.consent-actions, .conditions-of-use
  margin-bottom: 1rem
  font-size: 12px
  & a
    text-decoration: underline
    color: #363636

label
  display: flex
  align-items: center

  & > span
    min-width: 270px
    margin-left: 1rem
  @media screen and ( max-width: 768px )
    & > span
      min-width: min-content

  & > div.check
    height: 15px
    width: 15px
    border: 1px solid #363636
    border-radius: 2px
    &:hover
      border: 1px solid #FF6441

    &.active
      border: 0
      background: url(/static/images/auth_check_box.svg)

</style>
