<template>
  <div class="join-intro">
    <h1 class="join-intro__title"><span class="join-intro__title--primary">Join Pouch</span> for more benefits!</h1>
    <p class="join-intro__desc">Join the Pouch family and <span class="join-intro__title--bold">earn Pouch Points</span> by shopping at participating retailers, and completing certain tasks.</p>
  </div>
</template>

<script>
export default {
  props: {},
  methods: {}
}
</script>

<style lang="sass" scoped>
.join-intro
  &__title
    font-weight: 800
    font-size: 32px
    line-height: 135%
    margin-bottom: 16px
    text-align: center
    &--bold
      font-weight: 700
    @media (min-width: 1024px)
      text-align: left
    &--primary
      color: #FF6441
  &__desc
    text-align: center
    font-weight: 500
    font-size: 17px
    line-height: 150%
    color: #7A7A7A
    margin-bottom: 16px
    @media (min-width: 1024px)
      text-align: left
      max-width: 600px
    &--bold
      font-weight: 600
</style>
